.payment-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .payment-information-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: 840px) {
    .payment-information-container {
      flex-direction: column;
    }
  }
}
