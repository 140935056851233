.create-new-group-form {
    width: 100%;
    .ant-form-iten {
        display: flex;
        width: 100%;
    }
    .create-button-container {
        width: 100%;
        text-align: center;
    }
}