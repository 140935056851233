.steppers-container {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  .steps-content {
    height: calc(100% - 100px);
    width: 100%;
  }
  .steps-action {
    display: flex;
    flex-direction: row-reverse;
    button {
      margin: 8px;
    }
  }
  @media screen and (max-width: 840px) {
    height: initial;
  }
}
