.interest-search-group-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .info-circle {
    margin: 0 8px;
  }
  .default-group-container {
    margin: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .default-group-name {
      color: #40adcf;
      cursor: pointer;
    }
    .default-actions {
      display: flex;
      button {
        margin: 0 8px;
      }
    }
  }
  @media screen and (max-width: 840px) {
    .default-group-container {
      width: 100%;
      flex-direction: column;
    }
  }
}
