.intermediate-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .statement {
        display: flex;
        h2 {
            margin-left: 10px;
        }
    }
}