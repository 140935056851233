@import '../node_modules/antd/dist/antd.css';

html {
  font-size: 16px;
  transition: 0.2s;
  overflow-x: hidden;
  body {
    margin: 0 auto;
    max-width: 65em;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Helvetica', 'Arial', sans-serif;
    color: #555;
    background-color: #eee;
    line-height: 1.5;
    padding: 0 1em;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:focus {
  outline: none;
}

ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h1,
h2,
strong {
  color: #333;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  color: rgba(0, 0, 0, 0);
}

a:hover {
  text-decoration: none;
}

#root {
  height: 100%;
}
