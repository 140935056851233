.block-container {
    width: 100%;
    margin-bottom: 16px;
    -webkit-box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.14);
    box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.14);
    > div {
        padding: 0;
        height: 100%;
        .block-title {
            display: flex;
            padding: 10px;
            background-color: #4BC47C;
            color: #fff;
            h2 {
                color: #fff;
            }
            .info-circle {
                margin: 0 8px;
            }
        }
        .block-content {
            padding: 10px;
            width: 100%;
            height: calc(100% - 51px);
        }
    }
}