.linked-account-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .account {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        h3 {
            text-transform: capitalize;
        }
    }
}