.cancel-subscription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
        cursor: pointer;
        color: blue;
        text-decoration: underline;
        text-transform: capitalize;
        margin: 8px;
        white-space: nowrap;
    }
}