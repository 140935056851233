.profile-container {
  display: flex;
  .profile-top {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile-middle {
    flex: 1;
    .onboarding-container {
      width: 100%;
      text-align: center;
      h2 {
        color: red;
        font-size: 16px;
      }
    }
    .profile-info-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .profile-info-block {
        .profile-info {
          width: 100%;
          h2 {
            text-transform: capitalize;
          }
        }
      }
    }
    .profile-bottom {
      width: 100%;
      text-align: center;
    }
  }
  &.incomplete-profile-container {
    flex-direction: column;
    align-items: center;
    .profile-top,
    .profile-middle {
      flex: initial;
    }
  }
  @media screen and (max-width: 840px) {
    height: initial;
    flex-direction: column;
    align-items: center;
    .profile-middle {
      flex: initial;
      width: 100%;
      .profile-info-container {
        width: 100%;
        .profile-info-block {
          flex-direction: column;
          .profile-info {
            display: flex;
            justify-content: space-between;
            h2,
            h4 {
              width: 50%;
              font-size: 14px;
            }
            h2 {
              text-align: left;
              word-wrap: break-word;
            }
            h4 {
              text-align: center;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .profile-bottom {
      margin: 8px;
    }
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 8px;
  }
}

.something-went-wrong {
  width: 100%;
  text-align: center;
}

.profile-form-container {
  label {
    text-transform: capitalize;
  }
  .save-button-container {
    width: 100%;
    text-align: center;
  }
}
