#header {
    box-sizing: border-box;
    background-color: transparent;
    z-index: 1000;
    width: 100%;
    padding: 0;
    max-width: 70em;
    margin: 0 auto;
    padding: 0 1em;
    margin-bottom: 16px;
    .header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-section__left {
            display: flex;
            cursor: pointer;
            .logo__img {
                max-width: 190px;
                width: 100%
            }
        }
        .header-section__right {
            font-size: 16px;
            p {
                color: #4BC47C;
                &:hover {
                    color: #E25252;
                }
            }
        }
    }
}